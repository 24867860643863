import React from 'react'
import PropTypes from 'prop-types'

import { Global, css } from '@emotion/react'
import tw, { GlobalStyles } from 'twin.macro'

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Global
      styles={css`
        html {
          ${tw`bg-darkest text-text`}

          p {
            ${tw`mb-4`}

            &:last-child {
              ${tw`mb-0`}
            }
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            ${tw`text-lg mb-4`}
          }
        }
      `}
    />
    <main tw="min-h-screen flex flex-col relative">
      <div tw="relative flex-grow font-body z-10">{children}</div>
    </main>
  </>
)

Layout.propTypes = {
  children: PropTypes.node,
}

Layout.defaultProps = {
  children: null,
}

export default Layout
